<template>
    <div class="chat-talk">
        <div style="flex: 1;">
            <div class="chat-title" v-if="messages.length===0">
                <img :src="titleImg" alt="">
                <p>您好！我是鑫AI课件小助手，您的智能备课伙伴！</p>
            </div>
            <div :class="['chat-container', messages.length > 0 ? 'have-message' : 'no-message']">
            <!-- 消息列表 -->
                <div class="message-list" ref="messageList" v-if="messages.length > 0">
                    <div v-for="(message, index) in messages" :key="index" :class="['message', message.type]">
                        <div :class="['message-content', {'current': message.type == 'sent'}]">
                            <el-image v-if="message.type != 'sent'" lazy class="user-image" :src="getImage(message.type)"></el-image>
                            <div 
                                v-if="message.type =='sent'"
                                :class="['content', {current: message.type == 'sent'}]"
                            >{{ message.text }}</div>
                            <chat-peeding
                                v-else-if="message.type =='pedding'"
                            ></chat-peeding>
                            <chat-talk-list v-else :message="message"></chat-talk-list>
                        </div>
                    </div>
                </div>

                <!-- 输入区域 -->
                <div class="input-area-box">
                    <div class="input-area-box-inner">
                        <div class="input-area">
                            <img :src="logoImg" alt="">
                            <textarea 
                                v-model="inputText" 
                                @keydown.enter.prevent="sendMessage" 
                                placeholder="请输入关键词   例：生成六年级数学课件" 
                                :class="['chat-input',{ current: isTextareaDisabled }]"
                                rows="1"
                                v-bind:disabled="isTextareaDisabled"
                            ></textarea>
                            <button 
                                v-if="messages.length===0"
                                @click="sendMessage" 
                                :class="['send-btn', 'light', { current: isTextareaDisabled }]"
                                v-bind:disabled="isTextareaDisabled"
                            >生成大纲</button>
                            <button 
                                v-else
                                @click="sendMessage" 
                                :class="['send-btn', { current: isTextareaDisabled }]"
                                v-bind:disabled="isTextareaDisabled"
                            >发送</button>
                            <button 
                                v-if="nextBtnType" 
                                @click="nextToStyle" 
                                :class="['send-btn', 'light', 'next', { current: isTextareaDisabled }]"
                                v-bind:disabled="isTextareaDisabled"
                            >选择PPT模板</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-bottom" v-if="messages.length===0">
                <img :src="bottomImg" alt="">
            </div>
        </div>
        <div style="height: 100%;" v-if="showHistories && false">
            <div class="manipulate-group" @click="handleShowHistoriesClick">
                <el-image :src="historiesImg" class="history-image"></el-image>
            </div>
            <div style="height: calc(100vh - 200px);width: 300px;overflow-x:hidden;overflow-y:auto;border: 1px solid red;" >
                <div v-for="(item, index) in 100">{{ item }}</div>
            </div>
        </div>
        <div v-else-if="false" @click="handleShowHistoriesClick" class="manipulate-group">
            <el-image :src="historiesImg" class="history-image"></el-image>
        </div>
    </div>
</template>
<script>
    import {
        ChatPPTModel
    } from '@/models/ChatPPT.js';
    import ChatTalkList from './ChatTalk/List.vue';
    import ChatPeeding from './ChatTalk/ChatPeeding.vue';
    import { mapState } from 'vuex';
    export default {
        name: 'ChatTalk',
        components: {
            ChatTalkList,
            ChatPeeding
        },
        data() {
            return {
                messages: [],
                inputText: '',
                textMsg: '',
                showHistories: false,
                nextBtnType: false,
                outline: {},
                isTextareaDisabled: false
            }
        },
        computed: {
            ...mapState({
                userImg: (state) => state.userImg,
            }),
            getImage() {
                return (type) => {
                    return type === 'sent' ? this.userImg : require('@/assets/images/chat.png');
                }
            },
            formatTime() {
                return (timestamp) => {
                    const date = new Date(timestamp);
                    return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
                }
            },
            historiesImg() {
                return require('@/assets/images/historical-record.png');
            },
            previewSrcList() {
                return [require('@/assets/images/historical-record.png')]
            },
            logoImg() {
                return require('@/assets/images/logo-icon.png');
            },
            titleImg() {
                return require('@/assets/images/ppt-talk-title.png');
            },
            bottomImg() {
                return require('@/assets/images/ppt-talk-bottom-img.png');
            }
        },
        mounted() {
            this.scrollToBottom();
        },
        updated() {
            this.scrollToBottom();
        },
        watch: {
            messages() {
                this.$nextTick(() => {
                    this.scrollToBottom();
                })
            }
        },
        methods: {
            async sendMessage() {
                if (this.inputText.trim() !== '') {
                    this.isTextareaDisabled = true;
                    this.messages.push({
                        type: 'sent',
                        text: this.inputText,
                        timestamp: Date.now()
                    });

                    this.messages.push({
                        type: 'pedding',
                        text: '正在思考中...',
                        sid: ''
                    });

                    this.scrollToBottom();

                    const chatPPTModel = new ChatPPTModel();
                    chatPPTModel.createOutline({
                        query: this.inputText
                    }).then((res) => {
                        if(res.data.code === '200') {
                            const len = this.messages.length;
                            
                            this.outline = res.data.data.outline;
                            this.messages[len - 1].text = this.outline;
                            this.messages[len - 1].sid = res.data.data.sid;
                            this.messages[len - 1].type = 'received';

                            this.textMsg = this.inputText;
                            this.inputText = '';
                            this.nextBtnType = true;
                            this.scrollToBottom();
                            this.isTextareaDisabled = false;
                        } else {
                            const len = this.messages.length;
                            this.messages[len - 1].sid = ''; // 备注错误
                            this.messages[len - 1].type = 'received';
                            this.messages[len - 1].text = '抱歉，我无法理解您的意思，请重新提问。';
                            this.textMsg = '';
                            this.scrollToBottom();
                            this.isTextareaDisabled = false;
                        }
                    });
                }
            },
            nextToStyle() {
                if(this.textMsg == '') {
                    this.$message.warning('请先提问');
                    return
                } 
                const len = this.messages.length;
                const lastCurrentItem = this.messages[len - 1];
                const lastTalkItem = this.messages[len - 2];
                this.$emit('nextToStyle', {
                    outline: lastCurrentItem.text,
                    query: this.textMsg
                })
            },
            scrollToBottom() {
                this.$nextTick(() => {
                    const messageList = this.$refs.messageList;
                    if (messageList) {
                        messageList.scrollTop = messageList.scrollHeight;
                    }
                })
            },
            handleShowHistoriesClick() {
                this.showHistories = !this.showHistories
            },
            /**
             * @Description: 重置
             * @Author: 武东超
             * @Date: 2025-03-12 11:24:19
             */            
            reenterInfo() {
                this.messages = [{
                    type: 'received',
                    text: '我是智能生成PPT助手，欢迎您的使用。请对给定的PPT内容进行提问或提出修改意见吧。',
                    timestamp: Date.now()
                }];
                this.inputText = '';
                this.showHistories = false;
                this.nextBtnType = false;
            }
        }
    }
</script>
<style scoped lang="scss">
.chat-talk {
    display: flex;
    background: url('@/assets/images/ppt-talk-bg.png') no-repeat;
    height: calc(100vh - 184px);
    background-size: cover;
    border-radius: 0 0 10px 10px;

    .chat-title {
        text-align: center;
        padding: 40px 0 20px;

        img {
            margin-bottom: 24px;
        }

        p {
            font-size: 18px;
            color: #262626;
            line-height: 14px;
        }
        
    }

    .chat-bottom {
        text-align: center;
    }
}

.chat-container {
    // max-width: 800px;
    // width: calc(100% - 400px);
    margin: 10px 110px;
    height: calc(100vh - 214px);
    display: flex;
    flex-direction: column;
    font-family: 'Arial', sans-serif;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(202,224,253,0.4);
    border-radius: 16px;

    &.no-message {
        height: 150px;
        background: transparent;
    }
}

.message-list {
    flex: 1;
    overflow-y: auto;
    padding: 20px 220px;
    border-top: 1px solid #eee;
}

.message {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
}
.message-content {
    width: 100%;
    display: flex;
    flex-direction: row;
}
.message-content.current {
    justify-content: flex-start;
    flex-direction: row-reverse;
}
.user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
    margin-right: 15px;
    border: 2px solid #eee;
}

.message.received {
    align-items: flex-start;
}

.message.sent {
    align-items: flex-end;
}

.content {
    max-width: 70%;
    padding: 12px;
    border-radius: 18px;
    font-size: 14px;
    margin-bottom: 4px;
    letter-spacing: 0.5px;
}

.content.current {
    background: linear-gradient(90deg, #165DFF 0%, #72C3FC 100%);
    border-radius: 8px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    box-sizing: border-box;
    padding: 11px 25px;
}

.content.received {
    background: #f0f0f0;
    border-radius: 18px 18px 18px 4px;
}

.message.received {
    align-items: flex-start;
}

.message.sent {
    align-items: flex-end;
}

.content {
    max-width: 70%;
    padding: 12px;
    border-radius: 18px;
    font-size: 14px;
    margin-bottom: 4px;
}

.content.received {
    background: #f0f0f0;
    border-radius: 18px 18px 18px 4px;
}

.content.sent {
    background: #d1e7dd;
    border-radius: 18px 18px 4px 18px;
}

.timestamp {
    font-size: 12px;
    color: #999;
    margin-left: 14px;
}


.input-area-box {
    box-shadow: 0px 7px 16px 0px rgba(81,160,253,0.5);
    border-radius: 20px;
    background-image: linear-gradient(90deg, #165DFF, #72C3FC);
}

.message-list+.input-area-box {
    margin: 10px 220px;
}

.input-area-box-inner {
    padding: 3px;
    background: transparent;
}

.input-area {
    display: flex;
    align-items: center;
    padding: 8px;
    background: #FFFFFF;
    border-radius: 20px;
    

    img {
        margin: 0 4px 0 10px;
        width: 32px;
        height: auto;
    }
}

.chat-input {
    height: 26px;
    line-height: 26px;
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    resize: none;
    font-size: 18px;
    margin-right: 10px;
    transition: border-color 0.2s;
    scrollbar-width: auto;
    font-family: Source Han Sans CN;
}
.chat-input::placeholder {
    font-size: 18px;
    color: #999999;
    font-family: Source Han Sans CN;
}
.chat-input.current {
    background-color: #f5f5f5;
}

.chat-input:focus {
    border: none;
    outline: none;
}

.send-btn {
    padding: 15px;
    background: #DDEAFF;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    transition: background 0.2s;
    font-weight: 400;
    font-size: 18px;
    color: #333333;
    line-height: 14px;
}

.send-btn:hover {
    background: #c4daff;
}

.send-btn.current {
    background-color: #f5f5f5;
}

.send-btn.current:hover {
    background: #f5f5f5;
}

.send-btn.light {
    padding: 18px 30px;
    background: linear-gradient(90deg, #165DFF 0%, #71C2FD 100%);
    border-radius: 12px;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    font-family: Source Han Sans CN;
}

.next {
    margin-left: 10px;
}







.manipulate-group {
    box-sizing: border-box;
    padding: 10px 10px 10px 0;
}
.history-image {
    width: 24px;
    cursor: pointer;
}

/* 自定义滚动条 */
.message-list::-webkit-scrollbar {
    width: 8px;
}

.message-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.message-list::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.message-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>