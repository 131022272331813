<template>
    <div class="list-group">
        <div class="list" v-if="message.sid">
            <div class="cover">
                <div class="type">封面</div>
                <div class="text">{{ message.text.title }}</div>
            </div>
            <div v-for="(item, index) in message.text.chapters" class="list-group">
                <div class="chapters-nums">
                    章节{{ index + 1 }}
                </div>
                <div class="contents">
                    <div class="chapters">
                        <div class="type">目录</div>
                        <div class="text">{{ item.chapterTitle }}</div>
                    </div>
                    <div class="main-body" v-for="(subItem, subIndex) in item.chapterContents">
                        <div class="type">正文</div>
                        <div class="text">{{ subItem.chapterTitle }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="text-error">{{ message.text }}</div>
    </div>
</template>
<script>
export default {
    name: 'ChatTalkList',
    props: {
        message: Object
    },
    watch: {
        message: {
            handler (val) {
                console.log(val,'[val-val]')
                // this.list = val
            },
            immediate: true,
            deep: true
        }
    },
    data () {
        return {
            list: {}
        }
    }
}
</script>
<style lang="scss" scoped>
.text-error {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 40px;
}
.list {
    width: 300px;

    .cover {
        width: 100%;
        display: flex;
        font-size: 16px;
        margin-bottom: 10px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
    }

    .list-group {
        width: 100%;
        display: flex;

        .chapters-nums {
            width: 54px;
            font-size: 14px;
            color: #333333;
            box-sizing: border-box;
            padding-top: 10px;
        }

        .contents {
            flex: 1;
            box-sizing: border-box;
            padding-left: 10px;

            .chapters {
                display: flex;
                margin-bottom: 10px;
                border: 1px solid #eeeeee;
                border-radius: 4px;
            }

            .main-body {
                display: flex;
                margin-bottom: 10px;
                border: 1px solid #eeeeee;
                border-radius: 4px;

                .type {
                    color: #4DB9AD;
                    background-color: #e4f9f8;
                }
            }
        }
    }
}

.type {
    width: 54px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background-color: #ebf3ff;
    color: #426ffa;
    font-size: 14px;
}

.text {
    flex: 1;
    font-size: 14px;
    color: #333333;
    height: 38px;
    line-height: 38px;
    margin-left: 10px;
}
</style>