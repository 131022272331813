// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/ppt-talk-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".chat-wrapper[data-v-48ac8fa0]{background-color:#fff;border-top-right-radius:6px;border-bottom-left-radius:6px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:cover;overflow:hidden;height:-webkit-calc(100vh - 184px);height:-moz-calc(100vh - 184px);height:calc(100vh - 184px)}.chat-wrapper .create-ppt[data-v-48ac8fa0]{width:100%;height:100%;border:1px solid red;position:relative;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-moz-box-pack:center;justify-content:center;background-color:#fff;z-index:30;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:83px 0 91px}.chat-wrapper .create-ppt .loading[data-v-48ac8fa0]{width:30%}.chat-wrapper.current[data-v-48ac8fa0]{border-radius:6px!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
