<template>
    <div>
        <chat-tabs
            v-if="hasAuth"
            :tabs="tabs"
            :idx="idx"
            @handleTabClick="handleTabClick"
        >    
        </chat-tabs>
        <div :class="['chat-wrapper', {current: idx === 2}, `${idx}-c`]">
            <chat-talk 
                v-show="idx === 0"
                @nextToStyle="nextToStyle"
            ></chat-talk>
            <component
                v-create-ppt-loading='comLoading'
                ref="currentComRef"
                :is="currentCom"
                :pptUrl="pptUrl"
                @nextToStyle="nextToStyle"
                @startGenerating="startGenerating"
                @handlerClickToDetail="handlerClickToDetail"
                @goBack="goBack"
            ></component>
        </div>
    </div>
</template>
<script>
    import ChatTabs from "./ChatPPT/ChatTabs.vue";
    import ChatTalk from "./ChatPPT/ChatTalk.vue";
    import ChatList from './ChatPPT/ChatList.vue';
    import ChatDetail from './ChatPPT/ChatDetail.vue';
    import ChatStyle from './ChatPPT/ChatStyle.vue';
    import {
        ChatPPTModel
    } from '@/models/ChatPPT.js';
    export default {
        name: 'ChatPPTScrollWrapper',
        components: {
            ChatTabs,
            ChatTalk,
            ChatList,
            ChatDetail,
            ChatStyle
        },
        data() {
            return {
                tabs: [
                    {
                        id: 0,
                        name: '对话'
                    },
                    {
                        id: 1,
                        name: '我的PPT'
                    }
                ],
                chatComs: [
                    ChatTalk,
                    ChatList,
                    ChatDetail,
                    ChatStyle
                ],
                idx: 0,
                createOutline: {
                    query: '',
                    businessId: '',
                    language: 'cn',
                    search: false
                }, // 生成大纲
                templateList: {
                    style: '简约',
                    color: '红色',
                    industry: '教育培训',
                    pageNum: 1,
                    pageSize: 20
                },
                createPptByOutline: {
                    query: '',
                    templateId: '',
                    outline: {},
                }, //根据大纲生成PPT
                pptUrl: 'http://124.71.235.241:9000/cloud-campus/school/common/c04c8dde1edb4fa080ffc28a9c5b80d3.pptx',
                comLoading: false
            }
        },
        computed: {
            hasAuth() {
                const noAuth = [2, 3];
                return !noAuth.includes(this.idx);
            },
            currentCom() {
                return this.chatComs[this.idx]
            },
            createPPTLoading() {
                return require('@/assets/images/create-ppt-loading.gif')
            }
        },
        methods: {
            /**
             * @Description: 切换tab
             * @Author: 武东超
             * @Date: 2025-03-11 16:02:21
             * @param {*} idx
             */            
            handleTabClick(tab) {
                if (this.comLoading) {
                    this.$message.warning('正在生成ppt,请稍后～')
                    return;
                }
                this.idx = tab.id;
            },
            /**
             * @Description: 下一步
             * @Author: 武东超
             * @Date: 2025-03-12 09:53:32
             */            
            nextToStyle(data) {
                this.idx = 3;
                this.createPptByOutline.query = data.query;
                this.createPptByOutline.outline = data.outline;
            },
            /**
             * @Description: 点击进入详情
             * @Author: 武东超
             * @Date: 2025-03-12 10:00:32
             */
            handlerClickToDetail(item) {
                this.pptUrl = item.pptUrl;
                this.idx = 2;
            },
            /**
             * @Description: 生成PPT
             * @Author: 武东超
             * @Date: 2025-03-12 11:00:05
             */            
            async startGenerating(data) {
                this.comLoading = true
                this.createPptByOutline.templateId = data.templateIndexId;
                const chatPPTModel = new ChatPPTModel();
                let res = await chatPPTModel.getCreatePdf(this.createPptByOutline)
                console.log(res,'[res]')
                if (res.data.code == '200') {
                    await chatPPTModel.downloadPdf({sid: res.data.data.sid}).then((res) => {
                        this.comLoading = false;
                        if (res.data.code == '200') {
                            this.pptUrl = res.data.data.pptUrl;
                            this.idx = 2;
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                } else {
                    this.comLoading = false;
                    this.$message.error(res.data.message);
                }
            },
            /**
             * @Description: 详情返回列表
             * @Author: 武东超
             * @Date: 2025-03-12 11:21:20
             */            
            goBack(source) {
                console.log('source',source)
                this.reenterInfo(source);
            },
            reenterInfo(source) {
                console.log('重新生成', this.$refs.currentComRef,'--comm',this.idx)
                if(source == 'detail') {
                    this.idx = 1;
                } else if (source == 'style') {
                    this.idx = 0;
                }
                // this.idx = 0;
                // this.$refs.currentComRef.reenterInfo();
            }
        }
    }
</script>
<style lang="scss" scoped>
    .chat-wrapper {
        background-color: #ffffff;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
        background: url('@/assets/images/ppt-talk-bg.png') no-repeat;
        background-size: cover;
        overflow: hidden;
        height: calc(100vh - 184px);

        .create-ppt {
            width: 100%;
            height: 100%;
            border: 1px solid red;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ffffff;
            z-index: 30;
            box-sizing: border-box;
            padding: 83px 0px 91px;
            .loading {
                width: 30%;
            }
        }
    }
    .chat-wrapper.current {
        border-radius: 6px !important;
    }
</style>