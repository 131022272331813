<template>
    <div class="chat-peeding">
        <el-image class="image" :src="peedingImage"></el-image>
        <span class="text"> 生成大纲中，请耐心等待~   </span>
    </div>
</template>
<script>
    export default {
        name: 'ChatPeeding',
        computed: {
            peedingImage() {
                return require('@/assets/images/peeding.gif')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .chat-peeding {
        width: 302px;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EEEEEE;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding-left: 16px;
        .image {
            width: 17px;
            height: 20px;
        }
        .text {
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            margin-left: 14px;
        }
    }
</style>